@font-face {
  font-family: 'syneregular';
  src: url('./fonts/syne-variablefont_wght-webfont.woff2') format('woff2'),
    url('./fonts/syne-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html * {
  font-family: 'syneregular', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
  font-style: normal;
}
